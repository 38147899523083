import { Button, Text } from '@chakra-ui/react';
import { usePrompt } from 'src/hooks';

const useYesNoPrompt = () => {
  const prompt = usePrompt();

  const colors = {
    pureBlack: '#000 !important',
    black: 'rgb(34, 34, 34) !important',
    gray: '#444 !important'
  };

  const openAsync = ({ body }) => {
    return prompt.openAsync({
      title: 'Confirmação',
      body,
      buttons: ({ focusableRef, onResolve, onClose }) => (
        <>
          <Button 
            onClick={onResolve} 
            rounded="full" 
            bg={colors.black}
            color='white' 
            _hover={{background: colors.pureBlack}} 
            _active={{
              transform: "scale(0.95)",
              background: colors.pureBlack,
            }}
          >
            Sim
          </Button>
          <Button
            ml={2}
            ref={focusableRef}
            rounded="full"
            onClick={onClose}
            colorScheme="gray">
            <Text color="#000000">Não</Text>
          </Button>
        </>
      ),
    });
  };

  return { ...prompt, openAsync };
};

export default useYesNoPrompt;
