export enum EventStatus {
  Draft = 0,
  Pending = 1,
  Published = 2,
  Cancelled = 3,
  Suspended = 4,
  PublishDenied = 5,
  Closed = 6,
  PendingWirecard = 7,
}

export enum TicketStatus {
  Confirmed = 1,
  Cancelled = 2,
  Used = 3,
  Analysis = 4,
  Passport = 5,
  Conditioned = 6,
}

export enum TicketTypeKind {
  Individual = 0,
  Passport = 1,
  Child = 2,
}

export type TicketTypeVisibility =
  | 'site'
  | 'pos'
  | 'promoters'
  | 'restricted'
  | 'if-available';

export enum SellStatus {
  Started = 0,
  Selected = 1,
  Cancelled = 2,
  WaitingForPayment = 3,
  PaymentCanceled = 4,
  Completed = 5,
  PDVCompleted = 6,
  PromoterCompleted = 7,
  PaymentInAnalysis = 8,
}

export enum PaymentType {
  CreditCard = 'credit_card',
  Pix = 'pix',
  Gratuito = 'free',
}

export enum CreditCardBrand {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Amex = 'AMEX',
  Diners = 'DINERS',
  Elo = 'ELO',
  Hiper = 'HIPER',
  Hipercard = 'HIPERCARD',
}

export enum PaymentMethodStatus {
  WaitingPayment = 'waiting_payment',
  Rejected = 'rejected',
  Pending = 'pending',
  Valid = 'valid',
  Invalid = 'invalid',
  Removed = 'removed',
  Failed = 'failed',
}

export enum TicketSellType {
  Site = 'Site',
  Pdv = 'PDV',
  Promoter = 'Promoter',
}

export type Uid = string;

export type PaymentMethod = {
  id: Uid;
  paymentType?: PaymentType;
  name: string;
  brand: CreditCardBrand;
  status: PaymentMethodStatus;
  createdAt: string;
};

export type Authentication = {
  token: string;
  email: string;
  id: number;
  name: string;
  creation: string;
  lastAccess: string;
  expiration: string;
};

export type BillingInfo = {
  name: string;
  docType: number;
  document: string;
  modificationTime: string;
};

export type User = {
  id: number;
  name: string;
  email: string;
  cellPhone: string;
  city?: string;
  state?: string;
  promoter?: boolean;
  organizer_page?: boolean;
  hasEvent?: boolean;
};

export type CouponTicketType = {
  id: number;
  name: string;
  sellStart: Date;
  sellEnd: Date;
};

export type FullEvent = {
  id: Uid;
  userId: number;
  userName?: string;
  name: string;
  slug?: string | null;
  hashTags?: string[];
  description: string;
  placeId: number;
  place?: EventPlace;
  schedules: EventSchedule[];
  ticketTypes?: TicketType[];
  hidden: boolean;
  categoryId: number;
  creationDate: string;
  status: number;
  totalSold: number;
};

export type MeEvent = {
  id: Uid;
  userId: number;
  userName?: string;
  userImgUrl: string;
  name: string;
  schedules: EventSchedule[];
  sessions?: EventSchedule[];
  ticketTypes?: TicketType[];
  totalQuantity?: number;
  categoryId: number;
  creationDate: string;
  publicationStatus: number; // TODO: improve type
  place?: EventPlace;
  totalSold: number;
};

export type BasicEvents = {
  count: number;
  items: BasicEvent[];
};

export type EventCoupon = {
  id: number;
  couponId: number | null;
  eventId: string;
  name: string;
  type: number;
  unlockPrice: number | null;
  percentageDiscount: number | null;
  priceDiscount: number | null;
  quantity: number | null;
  dateStart: Date | null;
  dateEnd: Date | null;
  ticketTypes: CouponTicketType[];
  active: boolean;
};

export type PromoterTicketType = {
  id: number;
  description: string;
  sellPrice: number;
  quantityAvailable: number;
  quantity: number;
  soldQuantity: number;
  reserved: number;
  sellableQuantity: number;
  scheduledBatchTicketTypeId: number;
  sellEnd: string;
  taxPrice: number;
  batch: string | null;
  isUnlimited: boolean;
};

export type Schedule = {
  start: string;
  end: string;
};

export type EventCategory = {
  id: number;
  name: string;
};

export type EventSchedule = {
  id?: number;
  start: string;
  end: string;
};

export type EventDetailTicketType = {
  id: number;
  name: string;
  price: number;
  taxPrice: number;
  sellEnd: string;
  quantity: number;
  restricted?: boolean;
  description: string;
  taxCCPixDiff: number;
  batch: string | null;
};

export type EventIntegration = {
  id: Uid;
  pixelId: string;
  adsId: string;
  adsLabel: string;
  analyticsUaNumber: string;
  creationTime: Date;
};

export type BasicEvent = {
  id: Uid;
  userId: number;
  userImgUrl: string;
  name: string;
  startDate: string;
  sessions?: EventSchedule[];
  minPrice: number;
  maxPrice: number;
  place: string;
};

export type EventPublicInfoPlace = {
  name: string;
  position: {
    lat: number;
    lng: number;
  };
  address: string;
};

export type EventPublicInfo = {
  id: Uid;
  userId: number;
  UserName: string;
  userImgUrl: string;
  name: string;
  description: string;
  paymentTypes: PaymentType[];
  place: EventPublicInfoPlace;
  schedules: Schedule[] | null;
  sessions?: EventSchedule[];
  ticketTypes: EventDetailTicketType[];
  integrations: EventIntegration | null;
  ticketStatus: number;
  status: EventStatus;
  sellStartDate?: string;
  moreEvents: BasicEvent[];
  hidden: boolean;
};

// ROUTE => `/event/${eventID}/promoter`
export type EventPromoter = {
  id: number;
  name: string;
  email: string;
  userImgUrl: string;
  eventId: Uid;
  eventName: string;
  eventPlace: string;
  eventStatus: number; // TODO: improve type
  eventSchedules: EventSchedule[];
  ticketTypes: PromoterTicketType[];
  totalSold: number;
  totalQuantity?: number;
};

export type AdTrackerUserInfo = {
  email: string
  firstName: string
  LastName: string
  phoneNumber: string
  address: AdTrackerUserInfoAddress

}

export type AdTrackerUserInfoAddress = {
  street: string
  streetNumber: string
  city: string
  state: string
  neighborhood: string
  postalCode: string
  country: string
}

export type EventPlace = {
  id: number;
  name: string;
  zipCode?: string | null;
  state: string;
  city: string;
  district: string;
  street: string;
  streetNumber: number;
  complement: string;
  latitude: number;
  longitude: number;
};

// START ------------- LIST OF GUESTS

export type SingleGuestObjectType = {
  id: string;
  eventId: string;
  name: string;
  companions: number;
  effectiveCompanions: number;
  recordTime: string;
  statusTime: string;
  status: number;
};
export type Guest = SingleGuestObjectType[]; //Nome igual ao da API

// ROUTE: /eventlistname/:eventID/listsname
// Retorna os nomes das listas de um determinado evento
export type GuestMultiple = {
  id: string;
  listName: string;
  eventId: string;
  timeLimit: string;
  totalQuantity: number;
  processedQuantity: number;
  slug: string;
};

export type GuestListRelatedResponse = {
  id: string;
  name: string;
  status: 1 | 2 | 3;
  eventID: string;
  listID: string;
  statusTime: string | null;
};

// END OF ------------ LIST OF GUESTS

export type TicketTypeChild = {
  id: number;
  name: string;
  batch?: string | null;
  description: string;
  ticketGroup?: string | null;
  sex?: string | null;
  priceType?: string | null;
  conditioning: boolean;
  schedules: Schedule[];
};

export type TicketType = {
  id: number;
  eventId: Uid;
  name: string;
  batch: string | null;
  description: string;
  ticketGroup: string | null;
  sex: string | null;
  priceType: string | null;
  quantity: number;
  reserved: number;
  quantityUnavailable: number;
  hasSell: boolean;
  forSale: boolean;
  free: boolean;
  price: number;
  tax: number;
  sellStartTicket?: number | null;
  nextTicketTypeId?: number | null;
  scheduledBatchTicketTypeId: number;
  sellStart: string;
  sellEnd: string;
  sessions: EventSchedule[];
  children?: TicketTypeChild[];
  visibility?: TicketTypeVisibility[] | Number;
  ticketReturnInSell?: {
    [key: string]: boolean;
  };
};

export type Ticket = {
  id?: Uid;
  eventId?: Uid;
  eventName?: string;
  eventOwnerId: number;
  userId?: number | null;
  userName: string | null;
  userEmail?: string | null;
  ticketsSettingsId?: number;
  name: string;
  fullName: string;
  /** @deprecated information contained in fullName property */
  batch: string | null;
  description: string;
  ticketGroup: string | null;
  sex: string | null;
  priceType: string | null;
  tax: number;
  price: number;
  sellPrice: number;
  status: TicketStatus;
  sellDate: string;
  sessions: EventSchedule[];
  sellId: number;
  alias?: string | null;
  placeId: number;
  placePosition: {
    latitude: number;
    longitude: number;
  };
  address?: string;
  kind: TicketTypeKind;
  passportTickets: PassportTicket[];
  ownerChanged: boolean;
};

export type CheckInPerDay = {
  totalCheckin: number;
  totalSession: number;
  date: string;
};

export type TicketsStat = {
  pdv: number;
  site: number;
  promoter: number;
  checkin: number;
  checkInPerDay: CheckInPerDay[] | null;
  total: number;
};

export type TicketInfos = {
  count: number;
  items: TicketInfo[];
};

export type TicketInfo = {
  id: Uid;
  sellId: number;
  // sellType: TicketSellType;
  sellType: 'Site' | 'PDV' | 'Promoter';
  sellDate: string;
  userId: number;
  userName: string;
  alias: string | null;
  batch: string | null;
  userCellPhone: string;
  sellOpenTime: string;
  sellCloseTime: string | null;
  eventId: Uid;
  eventName: string;
  description: string;
  value: number;
  status: 'Confirmado' | 'Cancelado' | 'Utilizado';
  statusTime: string | null;
  promoterTicketGroupId?: Uid | null;
};

export type PassportTicket = {
  id: Uid;
  ticketId: Uid;
  passportChildrenID: number;
  name: string;
  batch?: string | null;
  description: string;
  ticketGroup?: string | null;
  sex?: string | null;
  priceType?: string | null;
  status: TicketStatus;
  creationTime: string;
  checkinTime?: string | null;
  sessions: Schedule[];
};

export type EventBuyerInfo = {
  label: string;
  type: string;
  size?: number;
  required: boolean;
  defaultValue: string;
};

export type SellPayInfo =
  | {
    cardToken: string;
    issuer: string;
    buyerInfoData: { [key: string]: string };
    payerName: string;
    payerBirthdate: string;
    payerCpf: string;
    payerPhone: string;
    payerZipCode: string;
    payerAddress: string;
    payerDistrict: string;
    payerStreetNumber: string;
    payerComplement: string;
    payerState: string;
    payerCity: string;
    installments: number;
    owners: OwnerInfo[];
  }
  | {
    paymentType: PaymentType.CreditCard;
    paymentMethodId: string;
    cvv: string;
    installments: number;
    owners: OwnerInfo[];
  }
  | {
    paymentType: PaymentType.Pix;
    payerCpf?: string;
    payerPhone?: string;
    owners: OwnerInfo[];
  }
  | {
    paymentType: PaymentType.Gratuito;
    payerCpf?: string;
    payerPhone?: string;
    owners: OwnerInfo[];
  };

export type OwnerInfo = {
  ticketTypeId: number;
  name: string;
  buyerInfoData?: [
    {
      birth_date?: string;
      city?: string;
      state?: string;
    },
  ];
};

export type BuyInstallment = {
  installment: number;
  buyerFee?: number;
  sellerFee?: number;
  value: number;
  total: number;
};

export type CheckoutItem = {
  openedDate: string;
  expirationDate: string;
  mediator: 'pagarme' | 'wirecard';
  sellerKey: string;
  sellId: number;
};

export type PendingInvoice = {
  id: Uid;
  value: number;
  dueDate: string;
};

export type StatusValue = { pct: number; val: number };

export type SalesByLocalValue = {
  discount: number;
  pct: number;
  pctQtt: number;
  qtt: number;
  val: number;
};

export type SalesByTicketTypeValue = {
  pct: number;
  qtt: number;
  val: number;
};

export type StatusOfPayments = {
  processing: StatusValue;
  rejected: StatusValue;
  confirmed: StatusValue;
  total: number;
};

export type SalesPyramid = {
  total: number;
  visits: number;
  paymentScreen: number;
  paymentProcessed: number;
  paymentRejected: number;
  finalized: number;
};

export type PendingPayments = {
  [key: string]: StatusValue;
} & { total: number };

export type SalesByLocal = {
  [key: string]: SalesByLocalValue;
} & {
  total: number;
  totalCredit: number;
  totalCreditDiscount: number;
  totalDebit: number;
  totalDebitDiscount: number;
  totalDiscount: number;
  totalInstallmentSellerFee: number;
  totalMoney: number;
  totalMoneyDiscount: number;
};

export type SalesByTicketType = {
  [key: string]: SalesByTicketTypeValue;
} & { total: number };

export type SalesByTicketGroup = {
  ticketGroup: string;
  totalValue: number;
  quantity: number;
  pct: number;
};

export type DetailSalesByTicketTypes = {
  ticketType: string;
  /** @deprecated information contained in ticketType property */
  batch: string | null;
  quantity: number;
  total: number;
  discount: number;
  discountQuantity: number;
  totalPix: number;
  discountPix: number;
  discountPixQuantity: number;
  totalDebit: number;
  discountDebit: number;
  discountDebitQuantity: number;
  totalCredit: number;
  discountCredit: number;
  discountCreditQuantity: number;
  totalMoney: number;
  discountMoney: number;
  discountMoneyQuantity: number;
};

export type DetailSalesByOrigin = {
  origin: string;
  quantity: number;
  value: number;
  discount: number;
  discountQuantity: number;
  pixValue: number;
  pixDiscount: number;
  pixDiscountQuantity: number;
  debitValue: number;
  debitDiscount: number;
  debitDiscountQuantity: number;
  creditValue: number;
  creditDiscount: number;
  creditDiscountQuantity: number;
  moneyValue: number;
  moneyDiscount: number;
  moneyDiscountQuantity: number;
  details: DetailSalesByTicketTypes[];
};

export type ReportSalesByOrigin = {
  total: number;
  discount: number;
  discountQuantity: number;
  totalPdv: number;
  pdvDiscount: number;
  pdvDiscountQuantity: number;
  totalPromoters: number;
  promotersDiscount: number;
  promotersDiscountQuantity: number;
  totalOnline: number;
  totalPix: number;
  onlineDiscount: number;
  onlineDiscountQuantity: number;
  details: DetailSalesByOrigin[];
};

export type Stats = {
  onlineTotal: number;
  posTotal: number;
  promotersTotal: number;
};

// Confirmar com o Matheus o nome

export type HelpCenterObjectType = {
  category: string;
  createdAt: string;
  id: string;
  slug: string;
  subject: string;
  topic: string;
  slugTopic: string;
  updatedAt: string;
  frequent: boolean;
};

export type HelpCenterType = HelpCenterObjectType[];

export type HelpCenterArticleContentType = {
  id: string;
  subject: string;
  category: string;
  topic: string;
  slugTopic: string;
  content: string;
  createdAt: string;
  updatedAt: string;
};

export type ArticleFrequent = {
  subject: string;
  category: string;
  slug: string;
  slugTopic: string;
  topic?: string;
  order: number;
};

// `report/checkinsummary?event=${eventID}`
export type CheckinSummaryDetails = {
  batch: string;
  eventId: string;
  priceType: string | null;
  sex: string | null;
  ticketDate: string;
  ticketGroup: string;
  totalCheckInEvent: number;
  totalCheckInFiltered: number;
  totalTicketsEvent: number;
  totalTicketsFiltered: number;
};

// me/billingdata/check`
export type BillingDataStatus = {
  bankAccount: boolean;
  status: 'ok' | 'partial' | 'missing';
  userData: boolean;
};

// me/balance
export type Transfers = {
  available_amount: number;
  currency: string;
  recipient: {
    code: string;
    created_at: string;
    document: string;
    email: string;
    id: string;
    name: string;
    payment_mode: string;
    status: string;
    type: string;
    updated_at: string;
  };
  transferred_amount: number;
  waiting_funds_amount: number;
};

// me/withdraw/{userID}
export type HasAccountForWithdraw = {
  withdraws: Withdraw[];
  hasAccount: boolean;
};

export type Withdraw = {
  id: string;
  userId: string;
  comment?: string;
  transfers_id: string;
  recipientId: string;
  status:
  | 'created'
  | 'pending'
  | 'processing'
  | 'transferred'
  | 'failed'
  | 'canceled';
  amount: number;
  day: string;
  createdAt: string;
};

export type EventCouponSum = {
  id: string;
  couponId: string;
  name: string;
  type: number;
  unlockPrice: number;
  percentageDiscount: number;
  priceDiscount: number;
  quantity: number;
  dateStart: string;
  dateEnd: string;
  ticketTypes: CouponTicketType[];
  active: boolean;
  totalSell: number;
  totalPrice: number;
  totalDiscount: number;
  ticketCount: number;
};

// event/${id}/coupons
export type EventCouponTotals = {
  eventCoupons: EventCouponSum[];
  totals: {
    totalSell: number;
    totalPrice: number;
    totalDiscount: number;
    totalTicketCount: number;
  };
};
