import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import omit from 'lodash/omit';

import api, { isApiError, userApi } from 'src/api';
import { useToast } from 'src/hooks';
import { useMeQuery } from 'src/hooks/queries/user';
import { Storages } from 'src/appConstants';
import {
  BasicEvent,
  CheckoutItem,
  CreditCardBrand,
  EventCoupon,
  EventDetailTicketType,
  EventIntegration,
  EventPublicInfoPlace,
  EventSchedule,
  EventStatus,
  PaymentType,
  Schedule,
  Uid,
  User,
} from 'src/types/dto';
import { CartItemType } from 'src/types/ui';
import React from 'react';

type GetCouponParams = {
  eventId: string;
  couponName: string;
};

type PutSellDiscountParams = {
  sellId: number;
  couponName: string;
};

type GetOrApplyCouponParams = GetCouponParams & PutSellDiscountParams;

type CartStore = {
  carts: { [id: string]: CartItemType[] };
  getEventCart: (id: string) => CartItemType[];
  getEventCartItemsAdded: (id: string) => CartItemType[];
  getEventCartTotal: (id: string) => number;
  getEventCartTotalTickets: (id: string) => number;
  setEventCart: (id: string, cart: CartItemType[]) => void;
  reinitializeEventCart: (
    id: string,
    ticketTypes?: EventDetailTicketType[],
  ) => void;
};

type CheckoutStore = {
  checkouts: { [id: string]: CheckoutItem };
  getEventCheckout: (id: string) => CheckoutItem;
  clearEventCheckout: (id: string) => void;
  setEventCheckout: (id: string, payload: CheckoutItem) => void;
};

type DiscountStore = {
  discount: EventCoupon | null;
  setDiscount: (discount: EventCoupon | null) => void;
  clearDiscount: () => void;
  useApplyMutation: () => UseMutationResult<
    EventCoupon,
    Error,
    GetOrApplyCouponParams
  >;
};

export type Owners = {
  [key: string]: any;
};

export type CheckoutFormValues = {
  // credit card
  selectedPaymentType?: PaymentType;
  selectedCardId?: string;
  selectedCardName?: string;
  selectedCardBrand?: CreditCardBrand;
  selectedCardCvv: string;
  amountCharged: string;
  cardNumber: string;
  cardCvc: string;
  cardExpiration: string;
  cardToken: string;
  payerName: string;
  payerBirthdate: string;
  payerCpf: string;
  payerPhone: string;
  payerZipCode: string;
  payerAddress: string;
  payerDistrict: string;
  payerStreetNumber: string;
  payerComplement: string;
  noNumber: boolean;
  payerState: string;
  payerCity: string;
  installments: number;
  isAddressFieldsVisible: boolean;
  // pix
  amount?: number;
  qrCode: string;
  qrCodeUrl: string;
  expirationDifferenceInMinutes?: number;
  expiresAtInMilliseconds?: number;
  createdAtInMilliseconds?: number;
  // both
  owners: Owners;
  isDocumentRequired: boolean;
  isPhoneRequired: boolean;
  sellId: number
};

export type InvalidFieldName = keyof (Omit<CheckoutFormValues, 'owners'> &
  Owners);

type CheckoutFormValuesStore = {
  formValues: CheckoutFormValues;
  setFormValues: (formValues: Partial<CheckoutFormValues>) => void;
  setFormValueOwner: (id: string, value: any) => void;
  clearFormValues: () => void;
  clearSensitiveFormValues: () => void;
};

const defaultCheckoutFormValues: CheckoutFormValues = {
  selectedPaymentType: undefined,
  selectedCardId: undefined,
  selectedCardName: undefined,
  selectedCardBrand: undefined,
  selectedCardCvv: '',
  amountCharged: '',
  cardNumber: '',
  cardCvc: '',
  cardExpiration: '',
  cardToken: '',
  payerName: '',
  payerBirthdate: '',
  payerCpf: '',
  payerPhone: '',
  payerZipCode: '',
  payerAddress: '',
  payerDistrict: '',
  payerStreetNumber: '',
  payerComplement: '',
  noNumber: false,
  payerState: '',
  payerCity: '',
  installments: 1,
  isAddressFieldsVisible: false,
  amount: undefined,
  qrCode: '',
  qrCodeUrl: '',
  expiresAtInMilliseconds: undefined,
  createdAtInMilliseconds: undefined,
  owners: {},
  isDocumentRequired: false,
  isPhoneRequired: false,
  sellId: 0
};

const getCoupon = (params: GetCouponParams) =>
  api
    .get(`event/${params.eventId}/coupons/${params.couponName}`)
    .then(res => res.data);

const putSellDiscount = (params: PutSellDiscountParams): Promise<EventCoupon> =>
  userApi
    .put(`sell/${params.sellId}/discount/${params.couponName}`)
    .then(res => res.data);

export const useDiscountStore = create<DiscountStore>(set => ({
  discount: null,
  setDiscount: discount => set({ discount }),
  clearDiscount: () => set({ discount: null }),
  // TODO: migrate to hooks in src/hooks/mutations/coupon
  useApplyMutation: () => {
    const meQuery = useMeQuery();
    const toast = useToast();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const linkname = urlParams.get('linkname');
    const coupon = urlParams.get('coupon');
    const hasNavigated = React.useRef(false)

    return useMutation<EventCoupon, Error, GetOrApplyCouponParams>({
      mutationFn: ({ sellId, eventId, couponName }) => {
        return (meQuery.data as User)?.email
          ? putSellDiscount({ sellId, couponName })
          : getCoupon({ eventId, couponName });
      },

      onSuccess: response => {
        set({ discount: response });
        if(!coupon) {
          urlParams.set('coupon', response.name);
          
          if (linkname) {
            urlParams.set('linkname', linkname);
          }
          const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
          if(newUrl !== window.location.href && !hasNavigated.current) {
            hasNavigated.current = true
            navigate(newUrl, {replace: false});
          }
        }
      },
      onError: er => {
        set({ discount: null });

        if (isApiError(er)) {
          toast({
            description: er.data?.message ?? 'Ocorreu um erro inesperado',
            status: 'warning',
          });
        }
      },
    });
  },
}));

export const useCartStore = create<CartStore>((set, get) => ({
  carts: {},
  getEventCart: id => {
    return get().carts[id] ?? [];
  },
  getEventCartItemsAdded: id => {
    return get().carts[id]?.filter(cartItem => !!cartItem.quantity);
  },
  getEventCartTotal: id => {
    return get().carts[id]?.reduce(
      (acc, cartItem) =>
        cartItem.quantity > 0 ? acc + cartItem.quantity * cartItem.price : acc,
      0,
    );
  },
  getEventCartTotalTickets: id => {
    return get().carts[id]?.reduce(
      (acc, cartItem) => acc + cartItem.quantity,
      0,
    );
  },
  setEventCart: (id, cart) => {
    set(prev => ({ ...prev, carts: { ...prev.carts, [id]: cart } }));
  },
  reinitializeEventCart: (id, ticketTypes) => {
    const cart =
      ticketTypes?.map(ticketType => ({
        ticketTypeId: ticketType.id,
        name: ticketType.name,
        availableQuantity: ticketType.quantity,
        quantity: 0,
        restricted: ticketType.restricted,
        price: ticketType.price,
        taxPrice: ticketType.taxPrice,
        sellEnd: ticketType.sellEnd,
        description: ticketType.description,
      })) ?? [];
    set(prev => ({ ...prev, carts: { ...prev.carts, [id]: cart } }));
  },
}));

export const useCheckoutStore = create<CheckoutStore>()(
  persist(
    (set, get) => ({
      checkouts: {},
      getEventCheckout: (id: string) => get().checkouts[id] ?? {},
      clearEventCheckout: (id: string) => {
        set(prev => {
          const { [id]: _removed, ...rest } = prev.checkouts;
          return { checkouts: rest };
        });
      },
      setEventCheckout: (id: string, payload: CheckoutItem) => {
        set(prev => ({
          ...prev,
          checkouts: { ...prev.checkouts, [id]: payload },
        }));
      },
    }),
    { name: Storages.CHECKOUT },
  ),
);

export const useCheckoutFormValuesStore = create<CheckoutFormValuesStore>()(
  persist(
    (set, get) => ({
      formValues: defaultCheckoutFormValues,
      setFormValues: newValues => {
        set(prev => ({ formValues: { ...prev.formValues, ...newValues } }));
      },
      setFormValueOwner: (id, owner) => {
        set(prev => ({
          formValues: {
            ...prev.formValues,
            owners: { ...prev.formValues.owners, [id]: owner },
          },
        }));
      },
      clearFormValues: () => {
        set({ formValues: defaultCheckoutFormValues });
      },
      clearSensitiveFormValues: () => {
        set(prev => ({
          formValues: {
            ...prev.formValues,
            selectedCardCvv: '',
            cardNumber: '',
            cardCvc: '',
            cardExpiration: '',
            cardToken: '',
            payerName: '',
          },
        }));
      },
    }),
    {
      name: Storages.CHECKOUT_FORM_VALUES,
      serialize: state =>
        JSON.stringify(
          omit(state, [
            'state.formValues.cardNumber',
            'state.formValues.cardCvc',
            'state.formValues.cardExpiration',
            'state.formValues.cardToken',
            'state.formValues.payerName',
            'state.formValues.selectedPaymentType',
          ]),
        ),
    },
  ),
);

type EventInfo = {
  id: Uid;
  userId: number;
  UserName: string;
  userImgUrl: string;
  name: string;
  description: string;
  paymentTypes: PaymentType[];
  place: EventPublicInfoPlace;
  schedules: Schedule[] | null;
  sessions?: EventSchedule[];
  ticketTypes: EventDetailTicketType[];
  integrations: EventIntegration | null;
  ticketStatus: number;
  status: EventStatus;
  sellStartDate?: string;
  moreEvents: BasicEvent[];
  hidden: boolean;  // Adicione outros campos conforme necessário
};

type PrivateLinkStore = {
  eventInfo: EventInfo | null;
  linkName: string,
  clearEventInfo: () => void,
  fetchEventInfo: (eventId: string, linkName: string) => Promise<void>;
};

export const usePrivateLinkStore = create<PrivateLinkStore>(set => ({
  eventInfo: null,
  linkName: '',
  clearEventInfo: () => set({ eventInfo: null, linkName: '' }),
  fetchEventInfo: async (eventId: string, linkName: string) => {
    try {
      const response = await api.get(`eventinfo/${eventId}?linkname=${linkName}`);
      set({ eventInfo: response.data, linkName: linkName });
    } catch (error) {
      console.error('Erro ao buscar informações do evento:', error);
      set({ eventInfo: null, linkName: '' });
    }
  },
}));